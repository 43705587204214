import { isPlatform } from '@ionic/angular/standalone';
import { CapacitorStateStore } from '@svnl/shared';
import { Environment } from 'shared/model/environment';

const redirectUri = isPlatform('capacitor')
  ? 'svnl://vrijwilligers/signin-callback'
  : 'https://vrijwilligerstest.sportvisserijnederland.nl/signin-callback';
const postLogoutRedirectUri = isPlatform('capacitor')
  ? 'svnl://vrijwilligers/signout-callback'
  : 'https://vrijwilligerstest.sportvisserijnederland.nl/signout-callback';

export const environment: Environment = {
  production: false,
  serverUrl: 'https://vrijwilligerstest.sportvisserijnederland.nl/api',
  authentication: {
    settings: {
      authority: 'https://test.mijnsportvisserij.nl/auth/realms/Svnl',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: 'vrijwilligers',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: redirectUri,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      post_logout_redirect_uri: postLogoutRedirectUri,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      response_type: 'code',
      // revokeTokensOnSignout: true, // TODO discuss Maarten
      userStore: new CapacitorStateStore(),
    },
  },
  arcgis: {
    portalId: '06a7828a255249f7a0b7f1f11440fe59',
  },
};
