import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@svnl/shared';

@Injectable({
  providedIn: 'root',
})
export class SvvwAuthenticationService extends AuthenticationService {
  private router: Router = inject(Router);

  override async init(path: string): Promise<void> {
    await super.init(path);

    const user = this.getUser();

    if (user?.expired) {
      await this.userManager.signoutRedirect();
    }

    return this.router.navigateByUrl(path).then(() => undefined);
  }
}
