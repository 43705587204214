/* eslint-disable @typescript-eslint/naming-convention */
import { CapacitorStateStore } from '@svnl/shared';
import { Environment } from 'shared/model/environment';

export const environment: Environment = {
  production: false,
  serverUrl: 'https://vrijwilligerstest.sportvisserijnederland.nl/api',
  authentication: {
    settings: {
      authority: 'https://test.mijnsportvisserij.nl/auth/realms/Svnl',
      client_id: 'vrijwilligers',
      redirect_uri: 'https://vrijwilligerstest.sportvisserijnederland.nl/signin-callback',
      post_logout_redirect_uri: 'https://vrijwilligerstest.sportvisserijnederland.nl/signout-callback',
      response_type: 'code',
      // revokeTokensOnSignout: true, // TODO discuss Maarten
      userStore: new CapacitorStateStore(),
    },
  },
  arcgis: {
    portalId: '06a7828a255249f7a0b7f1f11440fe59',
  },
};
