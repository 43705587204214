import Color from '@arcgis/core/Color';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import { LatLng } from '@svnl/shared';
import { ActivityConfig } from 'activity/config/activity-config';
import { ActivityBox } from 'activity/model/activity-box';
import { Mailbox } from 'message/model/mailbox';
import { toolType } from 'tool/tool';

export class Constants {
  static readonly LANGUAGE_CODE = 'nl';
  static readonly FISHING_MASTER_CONFIG: ActivityConfig = {
    tool: toolType.fishingMaster,
    title: 'fishing-master.title',
  };
  static readonly FISHING_TOGETHER_CONFIG: ActivityConfig = {
    tool: toolType.fishingTogether,
    title: 'fishing-together.title',
  };
  static readonly MAILBOX_ICON = new Map<Mailbox, string>([
    ['INBOX', 'mail-unread'],
    ['SENT', 'cloud-upload'],
    ['CONCEPTS', 'pencil-outline'],
  ]);
  static readonly ACTIVITY_BOX_ICON = new Map<ActivityBox, string>([
    ['NEW', 'alert-circle'],
    ['OPEN', 'alarm'],
    ['UPCOMING', 'calendar-outline'],
    ['DONE', 'checkmark-circle'],
  ]);

  static readonly PRIMARY_COLOR_MARKER_SYMBOL = new SimpleMarkerSymbol({
    color: new Color([0, 104, 181]),
    outline: new SimpleLineSymbol({ color: [255, 255, 255] }),
  });

  static readonly GOOGLE_MAPS_URL =
    'https://www.google.com/maps/search/?api=1&query=#latitude,#longitude';

  static readonly INITIAL_TIME = '00:00';
  static readonly SVNL_BACKEND_DATE_FORMAT = 'yyyy-MM-dd';

  static readonly DEPRECATED_LAT_LNG: LatLng = { lat: 50, lng: 50 };
}
